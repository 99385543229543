import React, { useEffect, useRef } from 'react';
import NextLink from 'next/link';
import { useTheme } from '@emotion/react';
import ArrowLeft from '$icons/arrow-left.svg';
import Close from '$icons/close-bold.svg';
import type { MixedNode } from '../MobileMegaMenu';
import { Logo } from '$shared/components/Logo';
import { Icon } from '$shared/components';
import { NodeList } from '../NodeList';
import {
    StyledHeader,
    StyledHeaderTitle,
    StyledHeaderButton,
    StyledMenuPage,
    ScrollContainer,
    CurrentPageAnchor,
} from './styled';
import { StyledContent } from '../NodeItem/styled';
import { useMobileMenu } from '../../hooks/useMobileMenu';

type MenuPageProps = {
    nodes: MixedNode[];
    parentNode?: MixedNode;
    headlineText?: string;
    currentPageUrl?: string;
    onSelectNode?: (node?: MixedNode) => void;
    onGoBack?: () => void;
};

export const MenuPage = ({
    nodes,
    headlineText,
    currentPageUrl,
    onSelectNode,
    onGoBack,
}: MenuPageProps) => {
    const ref = useRef<HTMLDivElement>(null);
    const { colors } = useTheme();
    const { close } = useMobileMenu();

    /**
     * Focus first link on open
     */
    useEffect(() => {
        const element = ref.current;

        if (element) {
            const firstFocusableElement = element.querySelector<HTMLAnchorElement>('a, button');
            firstFocusableElement?.focus();
        }
    }, [nodes]);

    /**
     * Support keyboard navigation up and down within the navigation
     */
    const onKeyDownHandler = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (!ref.current) {
            return;
        }

        const activeElement = document.activeElement as HTMLAnchorElement;
        const focusableElements =
            ref.current?.querySelectorAll<HTMLAnchorElement>('a, button') || [];
        const currentFocusIndex = Array.from(focusableElements).indexOf(activeElement);

        if (event.key === 'ArrowDown') {
            const focusIndex = Math.min(currentFocusIndex + 1, focusableElements.length - 1);
            focusableElements[focusIndex]?.focus();
            event.preventDefault();
        } else if (event.key === 'ArrowUp') {
            const focusIndex = Math.max(currentFocusIndex - 1, 0);
            focusableElements[focusIndex]?.focus();
            event.preventDefault();
        }
    };

    return (
        <StyledMenuPage ref={ref} onKeyDown={onKeyDownHandler}>
            <StyledHeader>
                {onGoBack ? (
                    <>
                        <StyledHeaderButton onClick={onGoBack} position="left">
                            <Icon size="sm">
                                <ArrowLeft />
                            </Icon>
                        </StyledHeaderButton>
                        <StyledHeaderTitle variant="display6" children={headlineText} />
                    </>
                ) : (
                    <>
                        <Logo description="Logo - Frontpage" color={colors.red} small />
                    </>
                )}
                <StyledHeaderButton onClick={close} position="right">
                    <Icon size="sm">
                        <Close />
                    </Icon>
                </StyledHeaderButton>
            </StyledHeader>
            <ScrollContainer>
                {currentPageUrl && (
                    <NextLink href={currentPageUrl} passHref legacyBehavior>
                        <CurrentPageAnchor title={headlineText}>
                            <StyledContent children={`${headlineText}`} truncate />
                        </CurrentPageAnchor>
                    </NextLink>
                )}
                {!!nodes?.length && <NodeList nodes={nodes} onSelectNode={onSelectNode} />}
            </ScrollContainer>
        </StyledMenuPage>
    );
};
