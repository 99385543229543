import styled from '@emotion/styled';
import Underline from '$icons/drawn-underline.svg';

export const StyledNavigationItem = styled.div({
    position: 'relative',
});

export const StyledNavigationItemLink = styled.a<{ textColor: string }>(({ theme, textColor }) => ({
    display: 'flex',
    alignItems: 'center',
    margin: 'auto',
    height: 56,
    color: textColor,
    fontSize: theme.fontSizes.xs,
    transition: `color ${theme.animations.timingShort} ${theme.animations.easeOutCubic}`,
    textDecoration: 'none',
    cursor: 'pointer',
    letterSpacing: '0.04em',
    position: 'relative',
    top: '0.2em', // Fixed VAG rounded not being centered correctly

    '&:hover + svg': {
        opacity: 1,
    },

    '.no-js &': {
        color: theme.colors.black,
    },
}));

export const StyledUnderline = styled(Underline)<{ show?: number }>(({ show }) => ({
    opacity: show === 1 ? 1 : 0,
    fill: 'red',
    width: '100%',
    height: 5,
    position: 'absolute',
    bottom: 0,
    transition: 'all 0.3s ease',
}));
