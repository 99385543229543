import { Icon, Link } from '~/shared/components';
import {
    StyledPredictionsContainer,
    StyledPredictionsList,
    StyledPredictionsListItem,
    StyledPredictionsPanelHeadline,
} from './styled';
import NextLink from 'next/link';
import { PredictionResultItem } from '../../../M140ProductsList';
import SearchIcon from '$icons/search.svg';

type Predictions = {
    predictions: PredictionResultItem[];
    translationHeading: string;
    isSearchPage: boolean;
    searchUrl: string;
    trackSearchQuery: () => void;
    watchValue: string | null;
    wrapSearchTerm: (term: string, watchValue: string) => string;
};

export const SearchBarPredictions = ({
    predictions,
    translationHeading,
    isSearchPage,
    searchUrl,
    trackSearchQuery,
    watchValue,
    wrapSearchTerm,
}: Predictions) => {
    return (
        <StyledPredictionsContainer>
            <StyledPredictionsPanelHeadline variant="captionSm" as="strong">
                {translationHeading}
            </StyledPredictionsPanelHeadline>
            <StyledPredictionsList>
                {predictions?.map((item) => (
                    <StyledPredictionsListItem
                        key={`prediction_${item.term}_${item.rank}`}
                        type="prediction"
                    >
                        <Icon size="sm">
                            <SearchIcon />
                        </Icon>
                        <NextLink
                            passHref
                            shallow={isSearchPage}
                            href={`${searchUrl}?search=${item.term}`}
                            prefetch={false}
                            legacyBehavior
                        >
                            <Link
                                onClick={trackSearchQuery}
                                dangerouslySetInnerHTML={{
                                    __html: wrapSearchTerm(item.term, watchValue || ''),
                                }}
                            ></Link>
                        </NextLink>
                    </StyledPredictionsListItem>
                ))}
            </StyledPredictionsList>
        </StyledPredictionsContainer>
    );
};
