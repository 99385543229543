import { useRouter } from 'next/router';
import React, { forwardRef, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
    MenuSubSection,
    MetaMenuSection as IMetaMenuSection,
    Navigation,
} from '~/lib/data-contract';
import {
    StyledList,
    StyledDropDown,
    StyledDropDownContent,
    StyledListItem,
    StyledMetaContainer,
    StyledMenuContainer,
} from './styled';
import { variants } from './animations';
import { Backdrop, NavigationItem } from './components';
import { useReducedMotion } from 'framer-motion';
import { useClickAway } from 'react-use';
import { Gutter, StyledMaxWidth } from '~/shared/components';
import { MenuSection } from './components/MenuSection';
import { useTheme } from '@emotion/react';
import { useSearchState } from '../N25SearchBar';
import MetaMenuSection from './components/MetaMenuSection/MetaMenuSection';

export type Props = {
    navigation: Navigation;
    onActiveChange?: (active: boolean) => void;
    linkTextColor: string;
};

/**
 * A basic navigation with related mega menu.
 * covers basic SEO and accessibility needs.
 * Handles active/inactive state and close on route change.
 */
export const N30MegaMenu = memo(({ navigation, onActiveChange, linkTextColor }: Props) => {
    const dropdowns = useRef<Array<HTMLDivElement | null>>([]);
    const [activeIndex, setActiveIndex] = useState<number | null>(null);
    const navigationRef = useRef<HTMLElement>(null);
    const [delayTimeout, setDelayTimeout] = useState<NodeJS.Timeout | null>(null);
    const { asPath } = useRouter();
    const shouldReduceMotion = useReducedMotion();
    const { colors } = useTheme();
    const mainMenu =
        navigation?.mainMenu?.filter((navigationItem) => navigationItem?.link?.url) || [];
    const mouseRef = useRef<NodeJS.Timeout | null>(null);

    const {
        isOpen: isSearchOpen,
        setIsOpen: setIsSearchOpen,
        dropdownRef,
        isFocused: isSearchFocused,
    } = useSearchState();

    useClickAway(navigationRef, () => {
        setActiveIndex(null);
    });

    useEffect(() => {
        setActiveIndex(null);
    }, [asPath]);

    useEffect(() => {
        onActiveChange && onActiveChange(activeIndex !== null);
        if (activeIndex) {
            window.addEventListener('keyup', onEscapeKey);
            setIsSearchOpen(false);
        } else {
            window.removeEventListener('keyup', onEscapeKey);
        }

        return () => {
            window.removeEventListener('keyup', onEscapeKey);
        };
    }, [activeIndex]);

    const activeNode = useMemo(() => {
        if (!activeIndex && activeIndex != 0) return null;

        if (dropdowns.current?.[activeIndex]) {
            return dropdowns.current?.[activeIndex];
        }

        return null;
    }, [dropdowns, activeIndex]);

    const onEscapeKey = useCallback(
        (e: KeyboardEvent) => {
            if (e.key === 'Escape') {
                mouseRef.current && clearTimeout(mouseRef.current);
                setActiveIndex(null);
            }
        },
        [mouseRef],
    );

    const handlerMouseEnter = useCallback(() => {
        mouseRef.current && clearTimeout(mouseRef.current);
    }, [mouseRef]);

    const handlersMouseLeave = useCallback(() => {
        mouseRef.current && clearTimeout(mouseRef.current);

        mouseRef.current = setTimeout(() => {
            setActiveIndex(null);
        }, 800);
    }, [mouseRef]);

    return (
        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
        <nav ref={navigationRef} onMouseEnter={handlerMouseEnter}>
            {!shouldReduceMotion && (
                <Backdrop
                    imitate={isSearchOpen ? dropdownRef : activeNode}
                    onMouseEnter={() => {
                        if (activeNode) {
                            setActiveIndex(null);
                        }
                        if (isSearchOpen && !isSearchFocused) {
                            setIsSearchOpen(false);
                        }
                    }}
                    onClick={() => {
                        if (isSearchOpen) {
                            setIsSearchOpen(false);
                        }
                    }}
                />
            )}

            <StyledList>
                {mainMenu?.map((menuNode, mainMenuIndex) => {
                    const isActive = mainMenuIndex === activeIndex;
                    const { subSection, link, metaMenuSections = [] } = menuNode;
                    const filteredMetaMenuSections = metaMenuSections.filter((section) => {
                        return (
                            section.metaMenuItems?.length ||
                            (section?.pageReference?.url && section?.pageReference.title)
                        );
                    });
                    return (
                        <StyledListItem key={`menuItem_${mainMenuIndex}`}>
                            <NavigationItem
                                active={asPath === link?.url}
                                onMouseEnter={() => {
                                    const timeout = setTimeout(() => {
                                        if (subSection?.length) {
                                            setActiveIndex(mainMenuIndex);
                                        } else {
                                            setActiveIndex(null);
                                        }
                                    }, 220);
                                    setDelayTimeout(timeout);
                                }}
                                onMouseLeave={() => {
                                    delayTimeout && clearTimeout(delayTimeout);
                                }}
                                link={link}
                                textColor={activeIndex !== null ? colors.black : linkTextColor}
                            />
                            {!!subSection?.length && (
                                <DropdownMemo
                                    handlerMouseEnter={handlerMouseEnter}
                                    handlersMouseLeave={handlersMouseLeave}
                                    shouldReduceMotion={shouldReduceMotion}
                                    metaSections={filteredMetaMenuSections}
                                    subSections={subSection}
                                    isActive={isActive}
                                    ref={(el) => (dropdowns.current[mainMenuIndex] = el)}
                                />
                            )}
                        </StyledListItem>
                    );
                })}
            </StyledList>
        </nav>
    );
});
N30MegaMenu.displayName = 'N30MegaMenu';

type DropdownMemoProps = {
    isActive: boolean;
    shouldReduceMotion: boolean | null;
    metaSections: IMetaMenuSection[];
    subSections: MenuSubSection[];
    handlerMouseEnter: () => void;
    handlersMouseLeave: () => void;
};

const DropdownMemo = memo(
    forwardRef<HTMLDivElement, DropdownMemoProps>(
        (
            {
                isActive,
                shouldReduceMotion,
                metaSections,
                subSections,
                handlerMouseEnter,
                handlersMouseLeave,
            },
            ref,
        ) => {
            const animationVariants = variants(shouldReduceMotion);

            return (
                <StyledDropDown
                    variants={animationVariants}
                    style={{ display: isActive ? 'block' : undefined }}
                    animate={isActive ? 'active' : 'inactive'}
                    initial="inactive"
                    ref={ref}
                >
                    <StyledMaxWidth>
                        <Gutter>
                            <StyledDropDownContent>
                                <StyledMetaContainer
                                    onMouseLeave={handlersMouseLeave}
                                    onMouseEnter={handlerMouseEnter}
                                >
                                    {metaSections.map((metaMenuSection, metaMenuSectionIndex) => (
                                        <MetaMenuSection
                                            key={`MetaMenu_${metaMenuSectionIndex}`}
                                            metaMenuSection={metaMenuSection}
                                        />
                                    ))}
                                </StyledMetaContainer>
                                <StyledMenuContainer
                                    columns={subSections.length > 2 ? 3 : 2}
                                    onMouseLeave={handlersMouseLeave}
                                    onMouseEnter={handlerMouseEnter}
                                >
                                    {subSections.map((menuSection, menuSectionIndex) => (
                                        <MenuSection
                                            key={`MenuSection_${menuSectionIndex}`}
                                            menuSection={menuSection}
                                            numberOfSections={subSections.length}
                                        />
                                    ))}
                                </StyledMenuContainer>
                            </StyledDropDownContent>
                        </Gutter>
                    </StyledMaxWidth>
                </StyledDropDown>
            );
        },
    ),
);

DropdownMemo.displayName = 'DropdownMemo';
