import Menu from '$icons/menu.svg';
import React from 'react';
import { StyledMobileMenuButton, StyledMobileMenuButtonWrapper } from '../styled';
import { useMobileMenu } from '../../N35MobileMegaMenu/hooks/useMobileMenu';
import { theme } from '$theme';
import { Icon } from '~/shared/components';

type MobileMegaMenuButtonProps = {
    isWhite?: boolean;
};

export const MobileMegaMenuButton = ({ isWhite }: MobileMegaMenuButtonProps) => {
    const { toggle } = useMobileMenu();

    return (
        <StyledMobileMenuButtonWrapper>
            <StyledMobileMenuButton
                variant="Plain"
                disableHoverAnimation={true}
                onClick={toggle}
                style={{
                    color: isWhite ? theme.colors.white : theme.colors.black,
                }}
            >
                <Icon size="md">
                    <Menu aria-hidden="true" />
                </Icon>
            </StyledMobileMenuButton>
        </StyledMobileMenuButtonWrapper>
    );
};
