import React, { useEffect, useState } from 'react';
import { useDebounce, useWindowSize } from 'react-use';
import { StyledBackdrop, StyledWrapper } from './styled';

type Props = {
    imitate?: HTMLElement | null;
    onMouseEnter?: () => void;
    onClick?: () => void;
};

/**
 * Mimics an elements height based on it's `imitator` prop
 * handles resizing
 */
export const Backdrop = ({ imitate, onMouseEnter, onClick }: Props) => {
    const { width } = useWindowSize();
    const [backdropHeight, setBackdropHeight] = useState<number>(0);
    const [isClientSide, setIsClientSide] = useState(false);
    useEffect(() => {
        setIsClientSide(true);
    }, []);
    const setHeight = () => {
        if (!imitate) {
            setBackdropHeight(0);
            return;
        }

        if (imitate?.style?.display == 'none') {
            // Imitate animation have not yet begon, can't measure height.
            setTimeout(() => {
                setHeight();
            }, 15);
            return;
        }
        setBackdropHeight(imitate?.offsetHeight ?? 0);
    };

    useEffect(() => {
        setTimeout(() => {
            setHeight();
        });
    }, [imitate]);

    useDebounce(
        () => {
            setBackdropHeight(imitate?.offsetHeight ?? 0);
        },
        50,
        [width],
    );

    return (
        isClientSide && (
            <>
                <StyledWrapper
                    active={!!backdropHeight}
                    onMouseEnter={onMouseEnter}
                    onClick={onClick}
                />
                <StyledBackdrop
                    animate={{ height: backdropHeight }}
                    transition={{ ease: [0.25, 0, 0, 1], duration: 0.55 }}
                />
            </>
        )
    );
};
