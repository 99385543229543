import { StyledFlagImage, StyledMarketHeader } from './styled';
import { Image, Text } from '$shared/components';
import { countryCodeKey, getCountryName } from '~/lib/twoLetterIsoCodeCountryList';
import { useTranslation } from '~/shared/utils/translation';

type MarketSelectorListItemProps = {
    market: string;
};

export const MarketSelectorListItem = ({ market }: MarketSelectorListItemProps) => {
    const { translate } = useTranslation();

    const getMarketTitle = (marketKey: countryCodeKey | 'INT') => {
        if (marketKey == 'INT') {
            return translate('Kompan.MarketSelector.InternationalSite');
        }
        return getCountryName(marketKey);
    };
    return (
        <StyledMarketHeader>
            <StyledFlagImage>
                <Image
                    quality="50"
                    src={`/assets/flags/${market.toLowerCase()}.svg`}
                    width={50}
                    height={50 * 0.75}
                    objectFit={'contain'}
                    layout="responsive"
                />
            </StyledFlagImage>
            <Text variant="display5" as="p">
                {getMarketTitle(market.toUpperCase() as countryCodeKey)}
            </Text>
        </StyledMarketHeader>
    );
};
