import React, { useMemo } from 'react';
import NextLink from 'next/link';
import { Icon, Link, Text } from '~/shared/components';
import { RelevantContentItem, RelevantContentResponse } from '~/lib/data-contract';
import { useTranslation } from '~/shared/utils';
import { StyledContentContainer, StyledContentList, StyledContentListItem } from './styled';
import FileIcon from '$icons/file.svg';

type ContentListItems = {
    items: RelevantContentResponse[];
};

export const ContentListItems = ({ items }: ContentListItems) => {
    const { translate } = useTranslation();
    const relevantItems = useMemo(() => {
        if (!items) return [];

        const itemsList: RelevantContentItem[] = [];
        items.forEach((group) => {
            itemsList.push(...group.relevantContent);
        });

        return itemsList;
    }, [items]);

    return (
        <StyledContentContainer>
            <Text variant="captionSm" as="strong">
                {translate('Kompan.Search.ContentTitle')}
            </Text>
            <StyledContentList>
                {relevantItems?.map((item) => (
                    <StyledContentListItem>
                        <Icon size="sm">
                            <FileIcon />
                        </Icon>
                        <NextLink
                            href={item.callToAction as string}
                            passHref
                            prefetch={false}
                            legacyBehavior
                        >
                            <Link>
                                <Text variant="body">{item.headline}</Text>
                            </Link>
                        </NextLink>
                    </StyledContentListItem>
                ))}
            </StyledContentList>
        </StyledContentContainer>
    );
};
