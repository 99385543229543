import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';
import { Button, Link } from '~/shared/components';
import { breakpoints } from '~/theme';

export const StyledMenuColumn = styled.div({
    display: 'flex',
    flexDirection: 'column',
});

export const StyledMenuImage = styled.div<{ isLarge: boolean }>(
    ({ theme }) => ({
        position: 'relative',
        width: '100%',
        display: 'inline-block',
        overflow: 'hidden',
        marginBottom: theme.spaces[6],
        height: 0,
        paddingBottom: '70%',
        [breakpoints.lg]: {
            paddingBottom: 0,
        },
        img: {
            borderRadius: '45px 15px',
        },
        '> *': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
        },
    }),
    ifProp(
        'isLarge',
        {
            paddingBottom: '60%',
            [breakpoints.lg]: {
                paddingBottom: 0,
                height: 270,
            },
        },
        {
            paddingBottom: '70%',
            [breakpoints.lg]: {
                paddingBottom: 0,
                height: 200,
            },
        },
    ),
);

export const StyledMenuSectionTitleLink = styled(Link)(() => ({
    textDecoration: 'none',
    '&:hover': {
        textDecoration: 'none',
    },
}));

export const StyledLinkWrapper = styled.div(({ theme: { spaces, lineHeights, direction } }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: `0`,
    marginLeft: `-${spaces[4]}`,
    marginRight: `-${spaces[4]}`,

    span: {
        whiteSpace: 'normal',
        textAlign: direction === 'rtl' ? 'right' : 'left',
    },

    a: {
        margin: 0,
        paddingTop: spaces[1],
        paddingBottom: spaces[1],
        paddingLeft: spaces[4],
        paddingRight: spaces[4],
        lineHeight: lineHeights.base,
        minHeight: '3.7rem',
        // textDecoration: 'none',

        [breakpoints.lg]: {
            minHeight: '4.7rem',
            paddingTop: spaces[2],
            paddingBottom: spaces[2],
        },
    },
}));

export const StyledLinkButton = styled(Button)({
    textDecoration: 'none',
});

export const StyledSectionButtonWrapper = styled.div(({ theme: { spaces } }) => ({
    paddingTop: spaces[3],
    gridColumn: 'span 2',

    a: {
        margin: 0,
        paddingTop: spaces[4],
        paddingBottom: spaces[4],
        paddingLeft: spaces[4],
        paddingRight: spaces[4],
    },
}));
