import { Sidepanel, useKompanGetQuotePanelState, useTracking } from '$shared/components';
import { useFrame, useMarket, useTranslation } from '$shared/utils';
import { memo, useMemo } from 'react';
import { FormConversionSource } from '~/lib/data-contract';
import type { countryCodeKey } from '~/lib/twoLetterIsoCodeCountryList';
import { getCountryName } from '~/lib/twoLetterIsoCodeCountryList';
import {
    M100RichText,
    M40ContactForm,
    M42CatalogueForm,
} from '~/templates/blocks/components/DynamicBlock/DynamicBlock';
import { StyledKompanGetQuotePanelWrapper } from './styled';

export const KompanGetQuotePanel = memo(() => {
    const { visible, hide } = useKompanGetQuotePanelState();
    const { data: frame } = useFrame();
    const { culture, market } = useMarket();
    const { translate } = useTranslation();
    const { trackMenuFormLead, trackSidePanelOpen } = useTracking();

    const GetModule = () =>
        useMemo(() => {
            switch (frame?.navigation?.getAQuote?.getAQuoteModule?.type) {
                case 'm100RichTextModule':
                    return (
                        <M100RichText
                            {...frame.navigation.getAQuote.getAQuoteModule}
                            loadOnSidepanel
                            disableModuleAnimation
                        />
                    );
                case 'm40ContactFormModule':
                    if (visible) {
                        trackSidePanelOpen({
                            button_text: translate('Kompan.MainMenu.GetQuoteButtonLabel'),
                            form_type: 'm40ContactForm',
                        });
                    }
                    return (
                        <M40ContactForm
                            {...frame.navigation.getAQuote.getAQuoteModule}
                            loadOnSidepanel
                            disableModuleAnimation
                            conversionSource={FormConversionSource.MenuGetQuote}
                            disableTracking
                            trackingEvent={(data) => {
                                trackMenuFormLead({
                                    form_name: 'Menu Form',
                                    country:
                                        market && market !== 'INT'
                                            ? getCountryName(market as countryCodeKey)
                                            : data.Address1_Country,
                                });
                            }}
                        />
                    );
                case 'm42CatalogueFormModule':
                    if (visible) {
                        trackSidePanelOpen({
                            button_text: translate('Kompan.MainMenu.GetQuoteButtonLabel'),
                            form_type: 'm42CatalogueForm',
                        });
                    }
                    return (
                        <M42CatalogueForm
                            {...frame.navigation.getAQuote.getAQuoteModule}
                            loadOnSidepanel
                            disableModuleAnimation
                            conversionSource={FormConversionSource.MenuGetQuote}
                        />
                    );
                default:
                    return <></>;
            }
        }, [frame?.navigation?.getAQuote?.getAQuoteModule]);

    return (
        <Sidepanel culture={culture} open={visible} onClose={hide}>
            <StyledKompanGetQuotePanelWrapper>
                <GetModule />
            </StyledKompanGetQuotePanelWrapper>
        </Sidepanel>
    );
});

KompanGetQuotePanel.displayName = 'KompanGetQuotePanel';
