import { create } from 'zustand';

export type GalleryModalState = {
    // Use an UId to make sure that you are triggering the right modal
    galleryIdActive: number | string;
    visible: boolean;
    activeMediaId: string | undefined;
    show: (galleryId: number | string, mediaId?: string) => void;
    hide: () => void;
};

export const useGalleryModal = create<GalleryModalState>((set) => ({
    galleryIdActive: -1,
    visible: false,
    activeMediaId: undefined,
    show: (galleryId: number | string, imageId?: string): void => {
        set(() => ({
            galleryIdActive: galleryId,
            visible: true,
            activeMediaId: imageId,
        }));
    },
    hide: (): void => {
        set(() => ({
            visible: false,
            activeMediaId: undefined,
        }));
    },
}));
