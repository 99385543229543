import { create } from 'zustand';

export type MarketSelectorState = {
    visible: boolean;
    hide: () => void;
    show: () => void;
};

export const useMarketSelectorState = create<MarketSelectorState>((set) => ({
    visible: false,
    show: (): void => {
        set(() => ({
            visible: true,
        }));
    },
    hide: (): void => {
        set(() => ({
            visible: false,
        }));
    },
}));
