import React, {
    memo,
    FC,
    ReactNode,
    createContext,
    useState,
    useCallback,
    useRef,
    useContext,
    useEffect,
} from 'react';
import { InViewContext } from '$shared/utils/in-view';
import { injectGoogleReCaptchaScript } from './utils';

interface LazyRecaptchaProps {
    children: ReactNode;
    recaptchaKey: string;
    language: string;
}

type LazyRecaptchaContextType = {
    load: () => Promise<void>;
    ready: boolean;
    executeRecaptcha?: (action?: string) => Promise<string>;
};

export const LazyRecaptchaContext = createContext<LazyRecaptchaContextType>({
    load: () => Promise.resolve(undefined),
    ready: false,
});

export const LazyRecaptcha: FC<LazyRecaptchaProps> = memo((props) => {
    const { children, recaptchaKey, language } = props;
    const [ready, setReady] = useState(false);
    const loaderReference = useRef<Promise<void>>();
    const clientId = useRef<number | string>(recaptchaKey);
    const [greCaptchaInstance, setGreCaptchaInstance] = useState<null | {
        execute: (clientId: number | string, data?: { action?: string }) => Promise<string>;
    }>(null);

    const load = useCallback(() => {
        if (!loaderReference.current) {
            loaderReference.current = new Promise((complete) => {
                /**
                 * *********************************************************************************
                 * BEWARE!
                 *
                 * The following code has been lifted almost verbatim from the react-google-recaptcha-v3
                 * package on GitHub. Sadly, the code doesn't support lazy-loading on demand, so it's
                 * either "LOAD SCRIPTS ALWAYS FOREVER" or this. I tried dynamically injecting the
                 * "proper" context at root level instead of this, but because the context needs to
                 * wrap the entire app, changing it meant re-rendering the entire subtree, which led
                 * to some nasty side effects (to say nothing of the performance issues, which we are
                 * actively trying to get rid of here).
                 *
                 * This, at least, seems to still run the correct functions and callbacks when needed,
                 * and since the loading is now dependent on elements being in view, it will no longer
                 * be render-blocking. I'd wager it works.
                 *
                 * *********************************************************************************
                 */

                const onLoad = () => {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    if (!window || !(window as any).grecaptcha) {
                        console.warn(`RecaptchaProvider - Script not available`);

                        return;
                    }

                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    const grecaptcha = (window as any).grecaptcha;

                    grecaptcha.ready(() => {
                        setGreCaptchaInstance(grecaptcha);

                        setReady(true);
                        complete();
                    });
                };

                const onError = () => {
                    console.warn('Error loading google recaptcha script');
                };

                injectGoogleReCaptchaScript({
                    render: recaptchaKey,
                    language,
                    onLoad,
                    onError,
                });
            });
        }

        return loaderReference.current;
    }, []);

    const executeRecaptcha = useCallback(
        (action?: string) => {
            if (!greCaptchaInstance || !greCaptchaInstance.execute) {
                throw new Error('<GoogleReCaptchaProvider /> Google Recaptcha has not been loaded');
            }

            return greCaptchaInstance.execute(clientId.current, { action });
        },
        [greCaptchaInstance, clientId]
    );

    return (
        <LazyRecaptchaContext.Provider
            value={{
                ready,
                load,
                executeRecaptcha: greCaptchaInstance ? executeRecaptcha : undefined,
            }}
        >
            {children}
        </LazyRecaptchaContext.Provider>
    );
});

LazyRecaptcha.displayName = 'LazyRecaptcha';

export const useLazyReCaptcha = (forceActive?: boolean): LazyRecaptchaContextType => {
    const contextData = useContext(LazyRecaptchaContext);
    const { ready, load } = contextData;
    const { inView } = useContext(InViewContext);

    useEffect(() => {
        if ((forceActive || inView) && !ready) {
            load();
        }
    }, [forceActive, load, ready, inView]);

    return contextData;
};
