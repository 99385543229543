import { create } from 'zustand';

type UseMobileMenu = {
    // The current open state
    isOpen: boolean;

    // Specify the open state
    setIsOpen: (isOpen: boolean) => void;
};

/**
 * Global and shared store for the mobile menu.
 * Avoid using directly - use the `useMobileMenu` hook instead
 */
export const useMobileMenuState = create<UseMobileMenu>((set) => ({
    isOpen: false,
    setIsOpen: (isOpen) => set({ isOpen }),
}));
