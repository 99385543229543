import styled from '@emotion/styled';
import { theme } from '$theme';
import { motion } from 'framer-motion';
import { ifProp } from 'styled-tools';

export const StyledMain = styled.main({
    minHeight: '50vh',
});

export const StyledBlurWrapper = styled.div<{ isBlurred: boolean }>(
    ifProp('isBlurred', {
        filter: 'blur(4px)',
    })
);

export const StyledQuoteDetailsContainer = styled.div({
    display: 'flex',
    flexDirection: 'column',
});

export const StyledQuoteOverlay = styled(motion.div)({
    position: 'fixed',
    top: 0,
    right: 0,
    width: 400,
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.colors.white,
    zIndex: theme.zIndexes['3'],
    borderLeft: `1px solid ${theme.colors.grey20}`,
});

export const StyledTopSectionContainer = styled.div(({ theme }) => ({
    marginBottom: theme.spaces[8],
}));
