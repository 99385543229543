import styled from '@emotion/styled';

export const StyledMarketHeader = styled.div(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    color: theme.colors.black,
    textDecoration: 'none',
}));

export const StyledFlagImage = styled.div(({ theme }) => ({
    width: '30px',
    flexShrink: 0,
    marginRight: theme.direction === 'rtl' ? 0 :  theme.spaces[4],
    marginLeft: theme.direction === 'rtl' ?  theme.spaces[4] : 0,
}));
