import { create } from 'zustand';
import { PreviewModeUIState } from '$features/authentication';

export const usePreviewModeState = create<PreviewModeUIState>((set) => ({
    isPreviewUserAuthenticated: false,
    setIsPreviewUserAuthenticated: (authenticated: boolean) => {
        set(() => ({
            isPreviewUserAuthenticated: authenticated,
        }));
    },
}));
