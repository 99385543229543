import { User } from '@relewise/client';
import { fetcher } from '~/lib/fetcher';
import { publicRuntimeConfig } from '~/shared/utils/public-variables';
import { PopularSearchTermsRequestResponseType } from '../requests';
export type getRelewisePopularSearchTerms = {
    locale: string;
    user?: User;
};

export const getRelewisePopularSearchTerms = async ({
    locale,
    user,
}: getRelewisePopularSearchTerms) => {
    const { RELEWISE_API_URL, RELEWISE_ENVIRONMENT_ID, RELEWISE_SEARCH_API_KEY } =
        publicRuntimeConfig();

    const body = {
        currency: {
            value: 'DKK',
        },
        language: {
            value: locale,
        },
        displayedAtLocation: 'Search Overlay',
        user: user,
        filters: null,
        postFilters: null,
        relevanceModifiers: null,

        settings: {
            targetEntityTypes: ['Product', 'Content'],
            numberOfRecommendations: 6,
        },
    };

    const fetchData = () =>
        fetcher<PopularSearchTermsRequestResponseType>(
            `${RELEWISE_API_URL}/${RELEWISE_ENVIRONMENT_ID}/v1/PopularSearchTermsRecommendationRequest`,
            {},
            { Authorization: `APIKey ${RELEWISE_SEARCH_API_KEY}` },
            'POST',
            JSON.stringify(body),
        )
            .then((response) => response.json())
            .then((data) => {
                return data;
            });

    return fetchData();
};
