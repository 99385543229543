import styled from '@emotion/styled';
import { StyledResetButton } from '~/shared/components';

export const StyledLastSearchContainer = styled.div(({ theme }) => ({
    padding: `${theme.spaces[7]} 0 0`,
}));

export const StyledLastSearchList = styled.ul(({ theme }) => ({
    position: 'relative',
    display: 'flex',
    flexFlow: 'row wrap',
    overflow: 'hidden',
    maxHeight: '4rem',
    gap: theme.spaces[2],
    marginTop: theme.spaces[4],
}));
export const StyledLastSearchListItem = styled.li({
    position: 'relative',
    display: 'flex',
});

export const StyledButtonItem = styled(StyledResetButton)(({ theme }) => ({
    ...theme.fontVariants.button,
    display: 'flex',
    gap: theme.spaces[3],
    cursor: 'pointer',
    padding: `10px ${theme.spaces[3]}`,
    backgroundColor: theme.colors.grey05,
    borderRadius: '6px',

    '> span': {
        display: 'inline-block',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '140px',
    },
}));
