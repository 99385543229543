import styled from '@emotion/styled';

// If needed variations such as size should be added here
export const StyledLogo = styled.div<{ fill: string; small?: boolean }>(
    ({ fill, small, theme }) => ({
        svg: small
            ? {
                  transition: `fill ${theme.animations.timingMedium} ${theme.animations.easeOutCubic}`,
                  fill,
                  height: 32,
                  width: 81,
              }
            : {
                  transition: `fill ${theme.animations.timingMedium} ${theme.animations.easeOutCubic}`,
                  fill,
                  height: 56,
                  width: 140,
              },
    })
);
