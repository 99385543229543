import styled from '@emotion/styled';
import { motion } from 'framer-motion';

export const StyledWrapper = styled.div({
    flex: 1,
    height: '100%',
    width: '100%',
    overflow: 'hidden',
});

export const AnimatedSlide = styled(motion.div)({
    height: '100%',
});
