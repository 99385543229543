import React from 'react';
import NextLink from 'next/link';
import { useTheme } from '@emotion/react';
import ArrowRight from '$icons/arrow-right.svg';
import type { MenuLink } from '~/lib/data-contract';
import type { MixedNode } from '../MobileMegaMenu';
import { StyledAnchor, StyledButton, StyledContent, StyledSuffix } from './styled';

type NodeItemProps = {
    node: MixedNode;
    onSelectNode?: (node: MixedNode) => void;
};

export const NodeItem = ({ node, onSelectNode }: NodeItemProps) => {
    const { subSection, subMenuItems, link, pageReference, url } = node as MixedNode;
    const subItems = (subSection || subMenuItems) as MixedNode[];
    const anchor: MenuLink = link || pageReference || url;

    const asButton = !!subItems?.length;

    const { colors } = useTheme();

    const onClickHandler = () => onSelectNode?.(node);

    if (asButton) {
        return (
            <StyledButton onClick={onClickHandler}>
                <StyledContent children={link?.title || pageReference?.title} />
                <StyledSuffix children={<ArrowRight color={colors.red} />} />
            </StyledButton>
        );
    }

    if (anchor?.url) {
        return (
            <NextLink href={anchor.url} passHref legacyBehavior>
                <StyledAnchor title={anchor.title}>
                    <StyledContent children={anchor?.title} truncate />
                </StyledAnchor>
            </NextLink>
        );
    }

    return <></>;
};
