import styled from '@emotion/styled';
import { Text } from '$shared/components';
import { breakpoints } from '~/theme';
export const StyledMetaLink = styled.span({
    whiteSpace: 'nowrap',
    '&:hover': {
        textDecoration: 'underline',
    },
});

export const StyledMetaItem = styled(Text)(({ theme }) => ({
    color: theme.colors.grey60,
    lineHeight: theme.lineHeights.base,
    marginBottom: theme.spaces[2],
    '&:last-child': {
        marginBottom: theme.spaces[4],
        [breakpoints.lg]: {
            marginBottom: theme.spaces[6],
        },
    },
    a: {
        textDecoration: 'none',
    },
    [breakpoints.lg]: {
        marginBottom: theme.spaces[4],
    },
}));

export const StyledMetaTitle = styled(Text)(({ theme }) => ({
    whiteSpace: 'nowrap',
    marginBottom: theme.spaces[3],
    [breakpoints.lg]: {
        marginBottom: theme.spaces[5],
    },
    a: {
        textDecoration: 'none',
    },
}));
