import styled from '@emotion/styled';
import { Text } from '$shared/components';

export const StyledContentMasonryWrapper = styled.div(() => ({}));

export const StyledLoadMoreWrapper = styled.div(() => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
}));

export const StyledContentHeadline = styled(Text)(({ theme }) => ({
    marginBottom: theme.spaces[6],
}));

export const StyledContentContainer = styled.div(({ theme }) => ({
    display: 'flex',
    flexFlow: 'column',
    gap: theme.spaces[3],
    padding: `${theme.spaces[5]} 0`,
    borderBottom: `1px solid ${theme.colors.grey20}`,
    borderTop: `1px solid ${theme.colors.grey20}`,
}));

export const StyledContentList = styled.ul(({ theme }) => ({
    listStyle: 'none',
    display: 'flex',
    flexDirection: 'column',
    paddingRight: theme.spaces[1],
}));

export const StyledContentListItem = styled.li(({ theme }) => ({
    width: '100%',
    padding: `${theme.spaces[3]} 0`,
    display: 'flex',
    alignItems: 'center',
    gap: theme.spaces[3],
    color: theme.colors.black,
    textDecoration: 'none',

    p: {
        lineHeight: '1em',
    },
}));
