import Heart from '$icons/heart.svg';
import { useMemo } from 'react';
import { Icon } from '~/shared/components';
import { StyledFavoriteButton, StyledFavoriteLength } from './styled';
import { StyledNavigationButtonWrapper } from '../styled';
import { useTheme } from '@emotion/react';
import { useFrame } from '~/shared/utils';
import { useFavoriteStorage } from '~/templates/blocks/components/M140ProductsList';
import { useRouter } from 'next/router';

export const FavoritesButton = ({ isMenuTransparent }: { isMenuTransparent: boolean }) => {
    const { colors } = useTheme();
    const { data: frame, isMyKompan } = useFrame();
    const { favorites } = useFavoriteStorage();
    const router = useRouter();

    const favoritesLength = useMemo(
        () => favorites && Object.values(favorites).filter((value) => value === true).length,
        [favorites],
    );

    const url = frame?.staticLinks?.favoritePage?.url && `${frame?.staticLinks?.favoritePage?.url}`;

    return (
        !isMyKompan &&
        url && (
            <StyledNavigationButtonWrapper>
                <StyledFavoriteButton
                    aria-label={frame?.staticLinks?.favoritePage?.title}
                    color={isMenuTransparent ? colors.white : colors.black}
                    href={router.asPath.includes('preview=true') ? `${url}?preview=true` : url}
                    prefetch={false}
                >
                    {/* favorite length is read from localStorage before hydration is complete */}
                    <StyledFavoriteLength show={favoritesLength > 0} suppressHydrationWarning>
                        {favoritesLength}
                    </StyledFavoriteLength>

                    {favorites?.[0]}
                    <Icon size="md">
                        <Heart />
                    </Icon>
                </StyledFavoriteButton>
            </StyledNavigationButtonWrapper>
        )
    );
};
