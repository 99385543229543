import { useCallback, useEffect } from 'react';
import { Icon, Portal } from '$shared/components';
import { useRouter } from 'next/router';
import { useSearchState } from './hooks/useSearchState';
import { StyledSearchBox } from './styled';
import {
    StyledSearchBarClose,
    StyledSearchBarGutter,
    StyledSearchBarMaxWidth,
    StyledSearchBarPortalContainer,
    StyledSearchButtonClose,
} from '../N30MegaMenu/styled';
import { SearchBar } from '~/templates/blocks/components/SearchResults/components/SearchBar';
import { useScrollLock } from '~/shared/hooks';
import CloseIcon from '$icons/close-round.svg';
import { LAST_SEARCH_COOKIE_NAME } from '~/templates/blocks/components/SearchResults/components/LastSearch';
import { useCookie } from 'react-use';
import { saveSearchTerm } from '~/templates/blocks/components/SearchResults/utils/saveSearchedTerm';

export const N25SearchBar = () => {
    const router = useRouter();
    const { isOpen: isSearchOpen, setIsOpen } = useSearchState();
    const { lock, unlock, isLocked } = useScrollLock();

    const [LastSearchCookieValue, _updateLastSearchCookieValue] =
        useCookie(LAST_SEARCH_COOKIE_NAME);
    const { searchInputValue } = useSearchState();

    useEffect(() => {
        if (isSearchOpen && !isLocked) {
            lock();
        } else if (!isSearchOpen && isLocked) {
            unlock();
        }
    }, [isSearchOpen, isLocked]);

    const onEscapeKey = useCallback(
        (e: KeyboardEvent) => {
            if (e.key == 'Escape' && isSearchOpen) {
                handleCloseSearch();
            }
        },
        [isSearchOpen],
    );

    useEffect(() => {
        window.addEventListener('keyup', onEscapeKey);

        return () => {
            window.removeEventListener('keyup', onEscapeKey);
        };
    }, [onEscapeKey]);

    useEffect(() => {
        // Event Handler for when user uses back button in browser
        const onRouteChangeComplete = () => {
            setTimeout(() => {
                setIsOpen(false);
            });
        };

        // Add Event listened for route change
        router.events.on('routeChangeComplete', onRouteChangeComplete);

        // Clean listeners on unmount
        return () => {
            router.events.off('routeChangeComplete', onRouteChangeComplete);
        };
    }, []);

    const handleCloseSearch = () => {
        setIsOpen(false);

        saveSearchTerm({
            searchTermValue: searchInputValue,
            LastSearchCookieValue,
            _updateLastSearchCookieValue,
        });
    };

    return (
        <StyledSearchBox>
            <Portal>
                {isSearchOpen && (
                    <StyledSearchBarPortalContainer>
                        <StyledSearchBarMaxWidth>
                            <StyledSearchBarGutter>
                                <StyledSearchBarClose>
                                    <StyledSearchButtonClose onClick={handleCloseSearch}>
                                        <Icon size="lg">
                                            <CloseIcon />
                                        </Icon>
                                    </StyledSearchButtonClose>
                                </StyledSearchBarClose>
                                <SearchBar showPredictions={true} showLastSearch={true} />
                            </StyledSearchBarGutter>
                        </StyledSearchBarMaxWidth>
                    </StyledSearchBarPortalContainer>
                )}
            </Portal>
        </StyledSearchBox>
    );
};
