import React, { useEffect, useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import { AnimationDirectionType, animationVariations } from './animations';
import { StyledWrapper, AnimatedSlide } from './styled';
import { MenuPage } from '../MenuPage';
import type { MenuNode, MenuSubSection, MenuSubNode } from '~/lib/data-contract';
import { useRouter } from 'next/router';
import { lookupNodeById } from '../../utils/lookupNodeById';
import { lookupNodeByUrl } from '../../utils/lookupNodeByUrl';
import { lookupParentNode } from '../../utils/lookupParentNode';
import { useMobileMenu } from '../../hooks/useMobileMenu';

type MobileMegaMenuProps = {
    menu: MenuNode[];
    activeNodeId?: string;
    activeNodeByUrl?: boolean;
};

export type MixedNode = MenuNode & MenuSubSection & MenuSubNode;

type MenuState = {
    node?: MixedNode;
    parentNode?: MixedNode;
};

export const MobileMegaMenu = ({ menu, activeNodeId, activeNodeByUrl }: MobileMegaMenuProps) => {
    const { asPath } = useRouter();
    const { close } = useMobileMenu();

    useEffect(() => {
        function handleEscapeKey(event: KeyboardEvent) {
            if (event.code === 'Escape') {
                close();
            }
        }

        document.addEventListener('keydown', handleEscapeKey);

        return () => document.removeEventListener('keydown', handleEscapeKey);
    }, []);

    const [animationDirection, setAnimationDirection] =
        useState<AnimationDirectionType>('forwards');

    const [activeMenuState, setActiveMenuState] = useState<MenuState>(() => {
        let node;
        if (activeNodeId) {
            node = lookupNodeById(activeNodeId, menu as MixedNode[]);
        } else if (activeNodeByUrl) {
            node = lookupNodeByUrl(asPath, menu);
        }
        return node ? { node, parentNode: lookupParentNode(node, menu) } : {};
    });

    const onGoBackHandler = () => {
        onSelectNodeHandler(activeMenuState.parentNode);
        setAnimationDirection('backwards');
    };

    const onSelectNodeHandler = (node?: MixedNode) => {
        if (node) {
            setActiveMenuState({ node, parentNode: lookupParentNode(node, menu) });
            setAnimationDirection('forwards');
        } else {
            // Go back to main menu
            setActiveMenuState({});
            setAnimationDirection('backwards');
        }
    };

    const { node } = activeMenuState || {};

    return (
        <StyledWrapper>
            <AnimatePresence mode="wait" initial={false} custom={animationDirection}>
                <AnimatedSlide
                    key={node?.id || node?.title}
                    variants={animationVariations}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                    custom={animationDirection}
                    transition={{
                        type: 'tween',
                        duration: 0.15,
                        ease: [0.35, 0, 0.15, 1],
                    }}
                >
                    {!node && (
                        <MenuPage nodes={menu as MixedNode[]} onSelectNode={onSelectNodeHandler} />
                    )}
                    {node && (
                        <MenuPage
                            nodes={(node.subSection || node.subMenuItems || []) as MixedNode[]}
                            headlineText={node.title || node.link?.title}
                            currentPageUrl={node.link?.url}
                            onGoBack={onGoBackHandler}
                            onSelectNode={onSelectNodeHandler}
                        />
                    )}
                </AnimatedSlide>
            </AnimatePresence>
        </StyledWrapper>
    );
};
