import NextLink from 'next/link';
import React, { memo } from 'react';
import { MenuLink } from '~/lib/data-contract';
import { StyledNavigationItem, StyledNavigationItemLink, StyledUnderline } from './styled';
import { useRouter } from 'next/router';

type Props = {
    link?: MenuLink;
    active?: boolean;
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
    textColor: string;
};

/**
 * Navigation item, which will render either as a link or button, based on it's childnodes presence.
 * has a active and inactive state.
 */
export const NavigationItem = memo((props: Props) => {
    const router = useRouter();
    const { link, onMouseEnter, onMouseLeave, active, textColor, ...rest } = props;

    if (link?.url) {
        return (
            <StyledNavigationItem onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                <NextLink
                    href={
                        router.asPath.includes('preview=true')
                            ? `${link.url}?preview=true`
                            : link.url
                    }
                    passHref
                    legacyBehavior
                >
                    <StyledNavigationItemLink
                        children={link.title}
                        title={link.title}
                        textColor={textColor}
                        {...rest}
                    />
                </NextLink>
                <StyledUnderline show={active ? 1 : 0} />
            </StyledNavigationItem>
        );
    }

    return <></>;
});

NavigationItem.displayName = 'NavigationItem';
