import styled from '@emotion/styled';
import { Button } from '$shared/components';
import { ifProp } from 'styled-tools';
import { StyledDropDown } from '../N30MegaMenu/styled';
import { breakpoints } from '~/theme';

export const StyledSearchBox = styled.div(({ theme }) => ({
    position: 'absolute',
    width: '100%',
    left: 0,
    top: 0,
    [`${StyledDropDown}:before, ${StyledDropDown}:after`]: {
        backgroundColor: theme.colors.white,
    },
}));

export const StyledSearchInput = styled.div<{ isOpen: boolean }>(
    () => ({
        width: '3em',
        opacity: 0,
    }),
    ifProp('isOpen', () => ({
        width: 'auto',
        opacity: 1,
    })),
);

export const StyledSearchButton = styled(Button)({
    backgroundColor: 'transparent',
    position: 'absolute',
    right: '2px',
    top: '2px',
});

export const StyledSearchDropdownContent = styled.div(({ theme }) => ({
    paddingTop: '100px',
    paddingBottom: theme.spaces[4],
    height: '100vh',
    [breakpoints.sm]: {
        height: 'auto',
        paddingTop: '170px',
    },
}));
