import React from 'react';
import {
    StyledButtonItem,
    StyledLastSearchContainer,
    StyledLastSearchList,
    StyledLastSearchListItem,
} from './styled';
import { useTranslation } from '~/shared/utils';

import { Icon, Text } from '~/shared/components';
import HistoryIcon from '$icons/history.svg';
import { useCookie } from 'react-use';

export const LAST_SEARCH_COOKIE_NAME = 'kom_last_search_list';
export const LAST_SEARCH_COOKIE_NAME_CONFIG = {
    secure: true, // must use https
    expires: undefined, // set as session
};

type LastSearch = {
    handleClick: (term: string) => void;
};

export const LastSearch = ({ handleClick }: LastSearch) => {
    const [searchedItems] = useCookie(LAST_SEARCH_COOKIE_NAME);

    const { translate } = useTranslation();

    const handleSelectRecentSearch = (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
        value: string,
    ) => {
        e.preventDefault();
        handleClick(value);
    };

    return (
        searchedItems &&
        searchedItems.length > 0 && (
            <StyledLastSearchContainer>
                <>
                    <Text variant="captionSm" as="strong">
                        {translate('Kompan.Search.LastSearchTitle')}
                    </Text>
                    <StyledLastSearchList>
                        {searchedItems
                            ?.split(';')
                            .reverse()
                            .splice(0, 12)
                            .map((item, idx) => (
                                <StyledLastSearchListItem key={item + '-' + idx}>
                                    <StyledButtonItem
                                        onClick={(e) => handleSelectRecentSearch(e, item)}
                                    >
                                        <Icon size="sm">
                                            <HistoryIcon />
                                        </Icon>
                                        <span>{item}</span>
                                    </StyledButtonItem>
                                </StyledLastSearchListItem>
                            ))}
                    </StyledLastSearchList>
                </>
            </StyledLastSearchContainer>
        )
    );
};
