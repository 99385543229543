import { StyledContainer, StyledInput, StyledButton } from './styled';
import ArrowRight from '$icons/arrow-right.svg';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useFrame } from '~/shared/utils';
import { useRouter } from 'next/router';
import { useTranslation } from '~/shared/utils/translation';

type NewsletterFormInputs = {
    email: string;
};

const NewsletterInput = () => {
    const { data: frame } = useFrame();
    const router = useRouter();
    const { translate } = useTranslation();

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors, isSubmitting },
    } = useForm<NewsletterFormInputs>();

    const onSubmit: SubmitHandler<NewsletterFormInputs> = ({ email }) => {
        if (isSubmitting) return;
        if (email && newsletterLink?.url) {
            // Important:to avoid exposing the email in the URL, we use the "as" parameter of push to mask the url.
            // The email will still appear in router.query on the receiving page.
            router.push(`${newsletterLink?.url}?email=${email}`, `${newsletterLink?.url}`);
        }
    };

    const { newsletterLink } = frame?.footer || {};

    return newsletterLink ? (
        <form onSubmit={handleSubmit(onSubmit)}>
            <StyledContainer isInvalid={Boolean(errors.email)}>
                <StyledInput
                    id={'email'}
                    type="email"
                    value={watch('email') || ''}
                    {...register('email', { required: true })}
                    placeholder={translate('Kompan.NewsletterForm.InputLabelFooter')}
                />
                <StyledButton disabled={!watch('email') || !newsletterLink.url || isSubmitting}>
                    <ArrowRight fontSize={14} />
                </StyledButton>
            </StyledContainer>
        </form>
    ) : null;
};

export default NewsletterInput;
