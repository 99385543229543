import styled from '@emotion/styled';
import { breakpoints } from '~/theme';

export const StyledContainer = styled.div<{ isInvalid: boolean }>(({ isInvalid, theme }) => ({
    display: 'flex',
    height: 50,
    borderBottom: `1px solid ${isInvalid ? theme.colors.red100 : theme.colors.black100}`,
    width: '100%',
    [breakpoints.md]: {
        width: 300,
    },
}));

export const StyledInput = styled.input(({ theme }) => ({
    ...theme.fontVariants.body,
    lineHeight: theme.lineHeights.single,
    flex: 1,
    height: '100%',
    backgroundColor: 'transparent',
    border: 'none',
    outline: 'none',
    color: theme.colors.black100,
}));

export const StyledButton = styled.button(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    height: '100%',
    width: 50,
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    transition: 'background 0.3s ease',

    '&:disabled': {
        opacity: 0.8,
        cursor: 'auto',
    },

    '> svg': {
        transition: 'color 0.3s ease',
        color: theme.colors.red,
    },

    '&:hover': {
        backgroundColor: theme.colors.grey20,
    },
}));
