import { Button, Text } from '$shared/components';
import { Fragment, useEffect, useState } from 'react';
import { RelevantContentItem, RelevantContentResponse } from '~/lib/data-contract';
import { useProductsStore } from '$templates/blocks/components/M140ProductsList';
import { ThreeColumn } from '$templates/blocks/components/M89RelevantContent/components/ThreeColumn';
import ArrowDown from '$icons/arrow-down.svg';
import { useTranslation } from '~/shared/utils/translation';
import {
    StyledContentHeadline,
    StyledContentMasonryWrapper,
    StyledLoadMoreWrapper,
} from './styled';
import { mapRelewiseContentItemToRelevantContent } from '$templates/blocks/components/M89RelevantContent/utils/mapRelewiseContentToRelevantContent';
import { ContentListItems } from './ContentListItems';

export enum ContentResultListType {
    REGULAR = 'regular',
    LIST = 'list',
}

type ContentResultList = {
    type?: ContentResultListType;
    perPage?: number;
};

export const ContentResultList = ({
    perPage = 6,
    type = ContentResultListType.REGULAR,
}: ContentResultList) => {
    const [page, setPage] = useState(0);
    const [pages, setPages] = useState<RelevantContentResponse[]>([]);
    const [totalPages, setTotalPages] = useState(0);

    const { contentResults } = useProductsStore();

    const { translate } = useTranslation();

    const splitResultsIntoPages = (results: RelevantContentItem[]): RelevantContentResponse[] => {
        const pages: RelevantContentItem[][] = [];
        for (let index = 0; index < results.length; index++) {
            const toPage = Math.floor(index / perPage);
            if (!pages[toPage]) {
                pages[toPage] = [];
            }
            pages[toPage].push(results[index]);
        }

        return pages.map((page) => {
            return {
                relevantContent: page,
                currentIndex: 0,
                totalNumberOfPages: Math.ceil(results.length / perPage),
                pageSize: perPage,
            };
        });
    };

    useEffect(() => {
        const mappedItems = contentResults.map((item) =>
            mapRelewiseContentItemToRelevantContent(item),
        );
        const mappedPages = splitResultsIntoPages(mappedItems);
        setPages(mappedPages);
        setTotalPages(Math.ceil(contentResults.length / perPage));
    }, [contentResults]);

    const loadMore = () => {
        if (page + 1 >= totalPages) {
            return;
        }
        setPage((page) => page + 1);
    };

    if (type === ContentResultListType.LIST) {
        return pages.length > 0 && <ContentListItems items={pages} />;
    }

    return (
        <div>
            <StyledContentHeadline variant="display1" as="h2">
                {translate('Kompan.Search.ContentHeadline')} ({contentResults.length})
            </StyledContentHeadline>

            {!contentResults ||
                (contentResults.length == 0 && (
                    <Text variant="display4">{translate('Kompan.Search.NoContentResults')}</Text>
                ))}
            {contentResults.length > 0 && (
                <Fragment>
                    <StyledContentMasonryWrapper>
                        <ThreeColumn items={pages.slice(0, page + 1)} />
                    </StyledContentMasonryWrapper>
                    <StyledLoadMoreWrapper>
                        {page + 1 < totalPages && (
                            <Button
                                variant="Transparent"
                                disableHoverAnimation={true}
                                icon={<ArrowDown />}
                                iconAlignment="end"
                                description={translate('Kompan.Commerce.LoadMore')}
                                onClick={loadMore}
                            >
                                {translate('Kompan.Commerce.LoadMore')}
                            </Button>
                        )}
                    </StyledLoadMoreWrapper>
                </Fragment>
            )}
        </div>
    );
};
