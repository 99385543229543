import styled from '@emotion/styled';
import { breakpoints } from '$theme';
import { Link, RawHtml, Text } from '~/shared/components';

export const StyledFooter = styled.footer(({ theme }) => ({
    backgroundColor: theme.colors.grey10,
    color: theme.colors.black100,
    padding: `${theme.spaces[8]} 0`,
    overflow: 'hidden',
    '&:empty': {
        display: 'none',
    },
    [breakpoints.md]: {
        padding: `${theme.spaces[10]} 0 ${theme.spaces[7]} `,
    },
}));

export const StyledFooterTitle = styled(Text)(({ theme }) => ({
    maxWidth: '80%',
    paddingBottom: theme.spaces[4],
}));

export const StyledGrid = styled.div(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: '1fr',
    gap: theme.spaces[8],
    paddingBottom: theme.spaces[8],
    [breakpoints.md]: {
        gridTemplateColumns: '1fr 1fr',
        gap: theme.spaces[6],
        paddingBottom: theme.spaces[10],
    },
}));

export const StyledLinkWrapper = styled.div(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    paddingBottom: theme.spaces[6],
    [breakpoints.md]: {
        paddingBottom: theme.spaces[8],
    },
    '&:last-of-type': {
        paddingBottom: 0,
    },
}));

export const StyledAddressWrapper = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    [breakpoints.md]: {
        width: 'auto',
        minWidth: `33.33%`,
        paddingRight: theme.spaces['4'],
        paddingBottom: 0,
    },
}));

export const StyledAddressText = styled(RawHtml)(({ theme }) => ({
    whiteSpace: 'break-spaces',
    a: {
        display: 'block',
        color: theme.colors.black,
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
}));

export const StyledMarketSelectorWrapper = styled.div(({ theme }) => ({
    width: '100%',
    flexShrink: 0,
    marginTop: theme.spaces[6],
}));

export const StyledLinkList = styled.ul(({ theme }) => ({
    width: '100%',
    paddingBottom: theme.spaces[6],
    [breakpoints.md]: {
        width: '33.33%',
        paddingBottom: 0,
    },
}));

export const StyledLink = styled(Link)(() => ({
    display: 'block',
    textDecoration: 'none',
    width: '100%',
}));

export const StyledBottomWrapper = styled.div(({ theme }) => ({
    display: 'flex',
    gap: theme.spaces[6],
    flexDirection: 'column',
}));

export const StyledBottomLinkList = styled.div(({ theme }) => ({
    display: 'flex',
    color: theme.colors.grey60,
    flexDirection: 'column',
    [breakpoints.md]: {
        flexDirection: 'row',
    },
}));

export const StyledBottomLink = styled(Link)(({ theme }) => ({
    display: 'block',
    textDecoration: 'none',
    [breakpoints.md]: {
        paddingLeft: theme.spaces[7],
    },
}));

export const StyledCopyrightText = styled(Text)(({ theme }) => ({
    color: theme.colors.grey60,
    paddingBottom: theme.spaces[8],
    [breakpoints.md]: {
        paddingBottom: 0,
    },
}));

export const StyledSectionHeader = styled(Text)(({ theme }) => ({
    color: theme.colors.grey60,
    marginBottom: theme.spaces[1],
}));

export const StyledSocialIconsWrapper = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    padding: `${theme.spaces['8']} 0`,
    gap: theme.spaces['4'],
}));

export const StyledSocialIconWrapper = styled.div({
    display: 'flex',
});

export const StyledSocialIconLink = styled(Link)(({ theme }) => ({
    display: 'flex',
    gap: theme.spaces['4'],
    color: theme.colors.black100,
    marginBottom: 0,
    textDecoration: 'none',
    alignItems: 'center',
    ':focus': {
        textDecoration: 'none',
    },
}));

export const StyledSocialIconImage = styled.div(({ theme }) => ({
    width: 30,
    height: 30,
    color: theme.colors.black100,
}));
