import { Icon, Text } from '~/shared/components';
import SearchIcon from '$icons/search.svg';
import {
    StyledPopularSearchButton,
    StyledPopularSearchContainer,
    StyledPopularSearchList,
    StyledPopularSearchListItem,
} from './styled';
import { useTranslation } from '~/shared/utils';
import { useQuery } from 'react-query';
import { getRelewisePopularSearchTerms } from '../../../M140ProductsList/components/lib/getRelewisePopularSearchTerms';
import { usePage } from '~/templates/pages';
import { useRelewiseTracking } from '~/shared/hooks/useRelewiseTracking/useRelewiseTracking';

type PopularSearches = {
    handleClick: (term: string) => void;
};

export const PopularSearches = ({ handleClick }: PopularSearches) => {
    const { translate } = useTranslation();
    const { market = 'INT', culture = 'en' } = usePage();
    const localeString = `${culture}-${market}`.toLowerCase();
    const { getUserObject } = useRelewiseTracking();

    const result = useQuery(
        ['relewise-search-term-prediction', localeString],
        () =>
            getRelewisePopularSearchTerms({
                locale: localeString,
                user: getUserObject(),
            }),
        {
            keepPreviousData: true,
            cacheTime: 1000 * 60 * 60 * 12, // 12 hours cache time
            staleTime: 1000 * 60 * 60 * 12, // 12 hours cache time,
            retryOnMount: false,
        },
    );

    const { data, isSuccess } = result;

    const handleClickSearchItem = (term: string) => {
        handleClick(term);
    };

    return (
        isSuccess &&
        data.recommendations.length > 0 && (
            <StyledPopularSearchContainer>
                <Text variant="captionSm" as="strong">
                    {translate('Kompan.Search.PopularSearchesTitle')}
                </Text>
                <StyledPopularSearchList>
                    {data.recommendations?.map((item) => (
                        <StyledPopularSearchListItem>
                            <Icon size="sm">
                                <SearchIcon />
                            </Icon>
                            <StyledPopularSearchButton
                                onClick={() => handleClickSearchItem(item.term)}
                            >
                                <Text variant="body">{item.term}</Text>
                            </StyledPopularSearchButton>
                        </StyledPopularSearchListItem>
                    ))}
                </StyledPopularSearchList>
            </StyledPopularSearchContainer>
        )
    );
};
