import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';
import { StyledResetButton, Text } from '~/shared/components';
import { StyledAnchor } from '../NodeItem/styled';

export const StyledMenuPage = styled.div({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
});

export const StyledHeader = styled.div(({ theme }) => ({
    width: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: `${theme.spaces[7]} ${theme.spaces[7]} 0 ${theme.spaces[7]}`,
}));

export const StyledHeaderButton = styled(StyledResetButton)<{ position: 'left' | 'right' }>(
    () => ({
        width: 'auto',
        height: 35,
        flexGrow: 0,
    }),
    ifProp({ position: 'left' }, ({ theme }) => ({
        paddingRight: theme.spaces[3],
    })),
    ifProp({ position: 'Right' }, ({ theme }) => ({
        paddingLeft: theme.spaces[3],
    }))
);

export const StyledHeaderTitle = styled(Text)({
    textAlign: 'center',
    flex: 1,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
});

export const ScrollContainer = styled.div(({ theme }) => ({
    overflowY: 'auto',
    paddingTop: theme.spaces[6],
}));

export const CurrentPageAnchor = styled(StyledAnchor)(({ theme }) => ({
    color: theme.colors.red,
    padding: `0 ${theme.spaces[7]}`,
}));
