import styled from '@emotion/styled';
import { StyledResetButton, Text } from '$shared/components';

export const StyledPopularSearchHeadline = styled(Text)(({ theme }) => ({
    marginBottom: theme.spaces[6],
}));

export const StyledPopularSearchContainer = styled.div(({ theme }) => ({
    display: 'flex',
    flexFlow: 'column',
    gap: theme.spaces[3],
    padding: `${theme.spaces[5]} 0`,
    borderBottom: `1px solid ${theme.colors.grey20}`,
}));

export const StyledPopularSearchList = styled.ul(() => ({
    listStyle: 'none',
    display: 'flex',
    flexDirection: 'column',
}));

export const StyledPopularSearchListItem = styled.li(({ theme }) => ({
    width: '100%',
    padding: `${theme.spaces[3]} 0`,
    display: 'flex',
    alignItems: 'center',
    gap: theme.spaces[3],
    color: theme.colors.black,
    textDecoration: 'none',

    p: {
        lineHeight: '1em',
    },
}));

export const StyledPopularSearchButton = styled(StyledResetButton)(() => ({
    textDecoration: 'underline',
}));
