import type { MenuNode } from '~/lib/data-contract';
import type { MixedNode } from '../components/MobileMegaMenu';

export function lookupNodeByUrl(
    url: string | undefined,
    haystack: MenuNode[] = []
): MixedNode | undefined {
    if (!url) return undefined;

    for (let i = 0; i < haystack.length; i++) {
        const node = haystack[i];
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        const { subSection, subMenuItems, link, pageReference } = node as any;
        const subItems: MixedNode[] = subSection || subMenuItems;

        // Check if current node is parent
        if (link?.url === url || pageReference?.url === url) {
            return node as MixedNode;
        } else if (subItems?.length) {
            // Check if parent in children
            const node = lookupNodeByUrl(url, subItems);

            if (node) {
                return node;
            }
        }
    }
}
