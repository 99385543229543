import { useRouter } from 'next/router';
import { ReactNode, useMemo } from 'react';
import { useAuth } from '~/features/authentication';
import { myKompanLoginPageUrlFallback } from '~/lib/constants';
import { ButtonProps } from '~/shared/components';
import { useFrame } from '~/shared/utils';
import { useTranslation } from '~/shared/utils/translation';
import { StyledNavigationActionButton } from '../styled';

interface LoginButtonProps extends Omit<ButtonProps, 'children'> {
    children?: ReactNode;
}

const LoginButton = (props: LoginButtonProps) => {
    const { translate } = useTranslation();
    const { isAuthenticated, logout } = useAuth();
    const router = useRouter();
    const { data } = useFrame();
    const goToLogin = () => {
        router.push(data?.staticLinks?.myKompanLoginPage?.url ?? myKompanLoginPageUrlFallback);
    };
    const handleLogout = () => {
        logout(data?.staticLinks?.myKompanLoginPage?.url || myKompanLoginPageUrlFallback);
    };

    const handleButtonClick = () => {
        if (isAuthenticated) {
            handleLogout();
        } else {
            goToLogin();
        }
    };

    const defaultButtonLabel = useMemo(() => {
        const key = isAuthenticated ? 'Kompan.Generic.Logout' : 'Kompan.Generic.Login';
        return translate(key);
    }, [isAuthenticated, translate]);

    return (
        <StyledNavigationActionButton {...props} onClick={handleButtonClick}>
            {props.children || defaultButtonLabel}
        </StyledNavigationActionButton>
    );
};

export default LoginButton;
