export const COUNTRY_REDIRECT_COOKIE_NAME = 'kom_country_redirect_ip';
export const COUNTRY_REDIRECT_SELECTED_MARKET = 'kom_country_redirect_market';
export const COUNTRY_REDIRECT_PERSIST_CONFIG = {
    secure: true, // must use https
    expires: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000), // set expiry to 30 days
};

export const COUNTRY_REDIRECT_SESSION_CONFIG = {
    secure: true, // must use https
    expires: undefined, // set as session
};
