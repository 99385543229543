import { create } from 'zustand';

export type KompanGetQuotePanelState = {
    visible: boolean;
    show: () => void;
    hide: () => void;
};

export const useKompanGetQuotePanelState = create<KompanGetQuotePanelState>((set) => ({
    visible: false,
    show: () => set(() => ({ visible: true })),
    hide: () => set(() => ({ visible: false })),
}));
