import type { MenuNode } from '~/lib/data-contract';
import type { MixedNode } from '../components/MobileMegaMenu';

export function lookupParentNode(
    needle: MixedNode | undefined,
    haystack: MenuNode[] = []
): MixedNode | undefined {
    if (!needle) return needle;

    for (let i = 0; i < haystack.length; i++) {
        const node = haystack[i];
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        const { subSection, subMenuItems } = node as any;
        const subItems: MixedNode[] = subSection || subMenuItems;

        // Check if current node is parent
        if (subItems?.includes(needle)) {
            return node as MixedNode;
        } else if (subItems?.length) {
            // Check if parent in children
            const parent = lookupParentNode(needle, subItems);

            if (parent) {
                return parent;
            }
        }
    }
}
