import { LAST_SEARCH_COOKIE_NAME_CONFIG } from '../components/LastSearch';

type saveSearchTerm = {
    searchTermValue?: string | null;
    LastSearchCookieValue?: string | null;
    _updateLastSearchCookieValue: (
        newValue: string,
        options?: Cookies.CookieAttributes | undefined,
    ) => void;
};

export const saveSearchTerm = ({
    searchTermValue,
    LastSearchCookieValue,
    _updateLastSearchCookieValue,
}: saveSearchTerm) => {
    const term = searchTermValue?.trim();

    if (term && term !== '') {
        const termList = LastSearchCookieValue?.split(';') || [];
        const index = termList.indexOf(term);

        if (index !== -1) termList.splice(index, 1);

        termList.push(term);

        _updateLastSearchCookieValue(termList.join(';'), LAST_SEARCH_COOKIE_NAME_CONFIG);
    }
};
