import styled from '@emotion/styled';
import { motion } from 'framer-motion';

export const StyledWrapper = styled.div<{ active: boolean }>(({ active, theme }) => ({
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '200vw',
    background: '#000000',
    zIndex: -1,
    opacity: active ? 0.3 : 0,
    pointerEvents: active ? 'auto' : 'none',
    cursor: 'pointer',
    transition: `opacity ${theme.animations.timingShort} ${theme.animations.easeOutCubic}`,
}));

export const StyledBackdrop = styled(motion.div)(({ theme: { colors } }) => ({
    height: 0,
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: colors.white,
    zIndex: -1,
}));
