import { MarketSelectorLanguage, MarketSelectorMarket } from '~/lib/data-contract';
import { LanguageCollection } from '../components/MarketSelectorDrawer';

export const flattenLanguages = (markets: MarketSelectorMarket[]) => {
    // This reducer will work in case markets will have more than 1 language attached in the future
    const allLanguages =
        markets.reduce((collection, market) => {
            const marketLanguages = market.languages || [];
            return [...collection, ...marketLanguages];
        }, [] as MarketSelectorLanguage[]) || [];

    const languageCollections = allLanguages.reduce((collection, marketLanguage) => {
        const [language, market] = marketLanguage?.culture?.toLowerCase()?.split('-') || [];
        if (!language || !market) return collection;

        if (collection[market]) {
            collection[market].languages.push(marketLanguage);
        } else {
            collection[market] = {
                market,
                languages: [marketLanguage],
            };
        }
        return collection;
    }, {} as LanguageCollection);
    return languageCollections;
};
