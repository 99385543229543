import React from 'react';
import { weakKey } from '~/shared/utils/jsx';
import type { MixedNode } from '../MobileMegaMenu';
import { NodeItem } from '../NodeItem';
import { StyledList } from './styled';

type NodeListProps = {
    nodes: MixedNode[];
    onSelectNode?: (node?: MixedNode) => void;
};

export const NodeList = ({ nodes, onSelectNode }: NodeListProps) => (
    <StyledList>
        {nodes?.map((node) => (
            <li key={weakKey(node)}>
                <NodeItem node={node} onSelectNode={onSelectNode} />
            </li>
        ))}
    </StyledList>
);
