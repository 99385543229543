import { css, Theme } from '@emotion/react';
import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';
import { Link, Text } from '~/shared/components';
import {
    StyledInputField,
    StyledInputFieldWrapper,
} from '~/shared/components/FormElements/components/InputField/styled';
import { StyledScrollArea } from '~/shared/components/ScrollArea/styled';
import { breakpoints } from '~/theme';

const baseButtonStyle = (theme: Theme) =>
    css({
        padding: 0,
        cursor: 'pointer',
        border: 'none',
        backgroundColor: 'transparent',
        width: theme.spaces[5],
        boxSizing: 'content-box',
        paddingLeft: theme.spaces[3],
        paddingRight: theme.spaces[3],
        textAlign: 'center',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        transitionDuration: theme.animations.timingShort,
        transitionTimingFunction: theme.animations.easeInOutQuad,
        transitionProperty: 'opacity, color',
        opacity: 1,
        [`figure, svg`]: {
            fill: 'currentColor',
            color: 'currentColor',
        },
    });

export const StyledSearchBarContainer = styled.div(() => ({
    display: 'flex',
    maxHeight: '100%',
    overflow: 'hidden',
    flexFlow: 'column',

    ['form']: {
        // height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    [`${StyledSearchInputWrap}`]: {
        flexShrink: 0,
    },
    [`${StyledScrollArea}`]: {
        flexShrink: 1,
        minHeight: '80px',
    },
}));

export const StyledSearchInputWrap = styled.div(({ theme }) => ({
    backgroundColor: theme.colors.grey05,
    display: 'flex',
    paddingRight: theme.spaces[4],
    ['input']: {
        lineHeight: theme.spaces[7],
        fontSize: theme.fontSizes.md,
        [breakpoints.sm]: {
            lineHeight: theme.spaces[8],
            fontSize: theme.fontSizes.lg,
        },
    },
    [`${StyledInputField}`]: {
        borderRadius: 0,
    },
    [`${StyledInputFieldWrapper}`]: {
        marginRight: 'auto',
        flexGrow: 1,
    },
}));

export const StyledSearchButton = styled.button<{ show?: boolean }>(
    ({ theme }) => ({
        ...baseButtonStyle(theme),
    }),
    ({ theme }) => ({
        color: theme.colors.red,
    }),
    ifProp({ show: false }, () => ({
        opacity: 0,
        pointerEvents: 'none',
    })),
);

export const StyledSearchClearButton = styled.button<{ show?: boolean }>(
    ({ theme }) => ({
        ...baseButtonStyle(theme),
    }),
    ({ theme, show }) => ({
        color: theme.colors.grey40,
        opacity: show ? 1 : 0,
        pointerEvents: show ? 'auto' : 'none',
        [':hover']: {
            color: theme.colors.grey100,
        },
    }),
);
export const StyledPredictionsPanel = styled.div(({ theme }) => ({
    position: 'relative',
    display: 'flex',
    flexFlow: 'column',
    overflow: 'hidden',
    minHeight: '80px', // prevent showing scrollbar when list is empty
    borderTop: `1px solid ${theme.colors.grey20}`,
    marginTop: theme.spaces[7],

    [breakpoints.md]: {
        display: 'grid',
        gridTemplateColumns: '360px 1fr',
        flexFlow: 'row',
    },
}));

export const StyledPredictionsPanelHeadline = styled(Text)(() => ({
    // color: theme.colors.grey60,
}));

export const StyledPredictionsContainer = styled.div(({ theme }) => ({
    display: 'flex',
    flexFlow: 'column',
    gap: theme.spaces[3],
    padding: `${theme.spaces[5]} 0`,
}));

export const StyledPredictionsList = styled.ul(() => ({
    listStyle: 'none',
    display: 'flex',
    flexDirection: 'column',
}));

export const StyledPredictionsListItem = styled.li<{ type: 'prediction' | 'shortcut' }>(
    ({ theme }) => ({
        width: '100%',
        padding: `${theme.spaces[3]} 0`,
        display: 'flex',
        alignItems: 'center',
        gap: theme.spaces[3],
        [`${Link}`]: {
            textDecoration: 'none',
            fontSize: theme.fontSizes.lg,
            [':hover']: {
                textDecoration: 'underline',
            },
        },
        [`.match`]: {
            color: theme.colors.black,
        },
        figure: {
            color: theme.colors.black,
        },
    }),
    ifProp({ type: 'prediction' }, ({ theme }) => ({
        color: theme.colors.grey40,
    })),
);

export const StyledSearchBarProductsResults = styled.div(({ theme }) => ({
    position: 'relative',
    display: 'flex',
    flexFlow: 'column',
    padding: `${theme.spaces[5]} ${theme.spaces[6]} 20px`,

    [breakpoints.md]: {
        borderLeft: `1px solid ${theme.colors.grey20}`,

        // padding: `${theme.spaces[5]} ${theme.spaces[6]} 220px`,
    },
}));

export const StyledViewAllButton = styled.div(({ theme }) => ({
    margin: `${theme.spaces[9]} auto`,
    width: 'auto',
}));