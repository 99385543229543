import styled from '@emotion/styled';
import { StyledAccordionHeader, StyledAccordionItem } from '~/shared/components/Accordion';
import { Link } from '$shared/components';
export const StyledSingleMarket = styled(StyledAccordionHeader)(({ theme }) => ({
    flexGrow: 1,
    cursor: 'auto',
    padding: `${theme.spaces[5]} 0`,
    borderTop: `1px solid ${theme.colors.grey20}`,
    borderBottom: `1px solid ${theme.colors.grey20}`,
    [`+ ${StyledAccordionItem}`]: {
        borderTop: 'none',
    },
    [`& + &`]: {
        borderTop: 'none',
    },
}));

export const StyledModalWrapper = styled.div(({ theme }) => ({
    display: 'block',
    width: '100%',
    paddingBottom: theme.spaces[7],

    [`${StyledAccordionHeader}`]: {
        padding: `${theme.spaces[5]} 0`,
    },
}));

export const StyledMarketLink = styled(Link)(({ theme }) => ({
    color: theme.colors.black,
    display: 'block',
    textDecoration: 'none',
    ['&:hover']: {
        textDecoration: 'underline',
    },
}));

export const StyledMarketLanguageLink = styled(StyledMarketLink)(({ theme }) => ({
    padding: `${theme.spaces[1]} 0`,
}));
